<template>
  <div :class="[this.$route.name]">
    <router-view ></router-view>
  </div>
</template>

<script>


export default {
  name: "App",
  components: {
  },
  data() {
    return {
      showHeader: true,
      isActive: false,
      userDataRanking: null,
      dataLoaded: false,
      themeClass: localStorage.getItem("theme-class") || false,
    };
  },
  methods: {
    youAPI() {
      this.dataLoaded = true
    },

    shadowMenu: function () {
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;

    },

    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },

    show3: function () {
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    // window.addEventListener('scroll', function () {
    //   if (window.pageYOffset > 10) {
    //     document.getElementById('header').classList.add('active')
    //   } else {
    //     document.getElementById('header').classList.remove('active')
    //   }
    // });

  },

  created() {
    let siteBody = document.body;
    siteBody.classList.remove("active");
    this.isActive = false;
  },

}
</script>
<style lang="scss" scoped>


</style>
