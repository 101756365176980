<template>
  <div>
    <HeaderComponent @sh0w="shadowMenu()"/>
    <div id="job-search">
      <div class="bluemark">JOB SEARCH</div>
      <div class="container">
        <div class="filter-div">
          <div class="row">
            <div style="padding-left: 0;" class="col-lg-3 desktop">
              <button @click="search()" class="search-btn">{{$t('message.Search')}}</button>
            </div>
            <div class="col-lg-6 col-md-7 searchbar">
              <div class="search-input">
                <div class="form">
                  <div class="form-group mb-0 has-search">
                  <span class="search-icon">
                    <i class="fa fa-search"></i>
                  </span>
                    <input v-model="keyword" class="form-control" type="text" placeholder="Enter your keyword or job title here"
                           id="example-search-input">

                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-5 desktop" style="padding-right: 0;">
              <button @click="listJobs()" class="reset-btn">{{$t('message.ResetFilters')}}</button>
            </div>
          </div>

          <div class="row mobileFlex">
            <div class="col-6" style="padding-right:5px;padding-left:0px;">
              <button @click="search()" class="search-btn">{{$t('message.Search')}}</button>
            </div>
            <div class="col-6" style="padding-right:0px;padding-left:5px;">
              <button @click="listJobs()" class="reset-btn">{{$t('message.ResetFilters')}}</button>
            </div>
          </div>
        </div>

      </div>
      <div class="container mt-5 jobslist">
        <router-link :to="'/job-page/'+item.id" class="job-wrapper" v-for="item in jobs" :key="item.index">
          <div class="icon-wrapper">
            <i :class="item.icon"></i>
          </div>
          <div class="content-wrapper">
            <div class="job-title">
              <h3>{{ item.title }}</h3>
            </div>
            <div class="job-location">
              <p class="d-inline-block">{{ item.location }}</p>
              <p class="d-inline-block ">{{ item.skills }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>

    <a href="#firstPage">
      <button id="top-btn" class="d-none d-lg-grid"
          @click="topFunction">
        <i class="fa-solid fa-arrow-up"></i>
        <span class="d-inline-block">Top</span>
      </button>
    </a>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";
import axios from 'axios'

export default {
  name: 'JobSearch',
  props: [],
  components: {
    HeaderComponent,
    SideBar,
  },
  data() {
    return {
      isActive: false,
      jobs: [],
      keyword: ''
    }
  },
  methods: {

    shadowMenu: function () {
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;

    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },

    listJobs() {
      axios.get(this.$api+'/listjobs.php')
      .then((response) => {
          var result = response.data;
          console.log(result)

          this.jobs = []
          for (let index = 0; index < result.length; index++) {
            result[index].icon = 'fa-solid fa-database'
            this.jobs.push(result[index])
          }
      });
    },

    search() {
      axios.get(this.$api+'/listjobs.php?keyword='+this.keyword)
      .then((response) => {
          var result = response.data;
          console.log(result)

          this.jobs = []
          for (let index = 0; index < result.length; index++) {
            result[index].icon = 'fa-solid fa-database'
            this.jobs.push(result[index])
          }
      });
    }
  },
  mounted() {
    this.listJobs()
  }
}
</script>

<style lang="scss" scoped>
#top-btn {
  color: #000;
}
</style>
