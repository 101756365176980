<template>
  <div id="sidebar" >
    <router-link class="sidebar-logo" to="/" @click="hideMenu">
      <img src="../assets/images/logowhite.png" alt="logo">
    </router-link>
    <button @click="hideMenu" class="close-btn">
      <!-- <i class="fa-solid fa-xmark"></i> -->
      <img style="max-height: 30px;" src="../assets/images/closex.png">
    </button>
    <ul class="sidebar-menu">
      <li>
        <a @click="hideMenu"
            href="/#secondPage"
           class="text-decoration-none"
             >{{ $t('message.mAboutus') }}
        </a>
      </li>
      <li>
        <a @click="hideMenu"
            href="/#thirdPage"
           class="text-decoration-none"
             >{{ $t('message.mOurServices') }}
        </a>
      </li>
      <li>
        <a @click="hideMenu"
        href="/#JobSearch"
           class="text-decoration-none"
             >{{ $t('message.mJobSearch') }}
      </a>
      </li>
      <li>
        <a @click="hideMenu"
        href="/#SubmitCV"
           class="text-decoration-none"
             >{{ $t('message.mSubmitCV') }}
      </a>
      </li>
      <li>
        <a @click="hideMenu"
        href="/#fourthPage"
           class="text-decoration-none"
             >{{ $t('message.mContactUs') }}
      </a>
      </li>
    </ul>

  </div>
</template>

<script>


export default {
  name: 'SideBar',
  props: [],
  components: {},
  data() {
    return {
    }
  },
  methods: {
    hideMenu() {
      this.$emit("hide")
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
