<template>
  <div>
    <HeaderComponent @sh0w="shadowMenu()"/>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>
    <section id="services">
      <div class="bluemark">SERVICES</div>
      <div class="container-fluid desktop">
        <div class="row">
          <div class="col-lg-2 col-sm-2 order-lg-2">
            <ul class="nav nav-tabs  text-center text-lg-end" id="services-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="SAP-tab"
                        data-bs-toggle="tab" data-bs-target="#SAP"
                        type="button" role="tab" aria-controls="SAP"
                        aria-selected="true">SAP
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="business-tab"
                        data-bs-toggle="tab" data-bs-target="#business"
                        type="button" role="tab" aria-controls="business"
                        aria-selected="false">Business <br>Applications
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="Cloud-tab"
                        data-bs-toggle="tab" data-bs-target="#cloud"
                        type="button" role="tab" aria-controls="cloud"
                        aria-selected="false">Cloud
                </button>
              </li>
            </ul>
          </div>
          <div class="col-lg-10 order-lg-1 fixWidth">

            <div class="tab-content" id="services-tab-content">
              <div class="tab-pane fade show active" id="SAP" role="tabpanel" aria-labelledby="SAP-tab">
                <p>{{$t('message.StronglySAP')}}<br>
                  {{$t('message.OurSAPConsultants')}}<br>
                  {{$t('message.EnableUsto')}}<br>
                  {{$t('message.DigitalTransformation')}}<br>
                  {{$t('message.ConsequentlyExpanded')}}<br>
                  {{$t('message.ToEmbrace')}}</p>
                <ul>
                  <li><p> SAP S/4Hana</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.FuncModules')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.TechModules')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.ProjectManagement')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.ManagementTraining')}}</p></li>

                </ul>

              </div>
              <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                <p>{{$t('message.OnAScope')}}<br/>
                  {{$t('message.OurConsultantsWill')}} <br/>
                  {{$t('message.WeAreHereToHelp')}} <br/>
                  {{$t('message.TalentInSalesforce')}}        
                </p>
                <!--
                  <ul>
                  <li><p><span class="dot">⚫</span> SAP</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.Salesforce')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.MYSDynamics')}}</p></li>

                </ul>
                -->
              </div>
              <div class="tab-pane fade" id="cloud" role="tabpanel" aria-labelledby="Cloud-tab">
                <p>{{$t('message.EstimatedCloud')}} <br/>
                  {{$t('message.CloudWithHelp')}}
                </p>
                <ul>
                  <li><p><span class="dot">⚫</span> {{$t('message.DevOpsEng')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.DigitalPlatforms')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.CloudArch')}}</p></li>
                  <li><p><span class="dot">⚫</span> {{$t('message.DataEng')}}</p></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service-mobile mobile">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <tabs v-model="selectedTab">
                <tab v-for="(tab, index) in tabs" :key="index" :val="tab.value">
                  <div :id="tab.value" :class="{ 'active-tab': getActive(tab.value) }" class="tab">
                    {{ tab.label }}
                  </div>
                </tab>
              </tabs>
            </div>
            <div class="col-12">
              <tab-panels
                  v-model="selectedTab"
                  :animate="true"
                  :swipeable="true"
                  class="tab-panels"
              >
                <tab-panel
                    :val="1"
                    class="tab-panel"
                >
                  <div>
                    <p>{{$t('message.StronglySAP')}}<br>
                      {{$t('message.OurSAPConsultants')}}<br>
                      {{$t('message.EnableUsto')}}<br>
                      {{$t('message.DigitalTransformation')}}<br>
                      {{$t('message.ConsequentlyExpanded')}}<br>
                      {{$t('message.ToEmbrace')}}</p>
                    <ul>
                      <li><p><span class="dot">⚫</span> SAP S/4Hana</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.FuncModules')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.TechModules')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.ProjectManagement')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.ManagementTraining')}}</p></li>
                    </ul>
                  </div>
                </tab-panel>
                <tab-panel
                    :val="2"
                    class="tab-panel"
                >
                  <div>
                    <p>{{$t('message.OnAScope')}}<br/>
                      {{$t('message.OurConsultantsWill')}} <br/>
                      {{$t('message.WeAreHereToHelp')}} <br/>
                      {{$t('message.TalentInSalesforce')}}
                    </p>
                    <ul>
                      <li><p><span class="dot">⚫</span> SAP</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.Salesforce')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.MYSDynamics')}}</p></li>
                    </ul>
                  </div>
                </tab-panel>
                <tab-panel
                    :val="3"
                    class="tab-panel"
                >
                  <div>
                    <p>{{$t('message.EstimatedCloud')}} <br/>
                      {{$t('message.CloudWithHelp')}}
                    </p>
                    <ul>
                      <li><p><span class="dot">⚫</span> {{$t('message.DevOpsEng')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.DigitalPlatforms')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.CloudArch')}}</p></li>
                      <li><p><span class="dot">⚫</span> {{$t('message.DataEng')}}</p></li>

                    </ul>
                  </div>
                </tab-panel>
              </tab-panels>
            </div>
          </div>
        </div>
      </div>
      <a href="#firstPage">
        <button id="top-btn" class="d-none d-lg-grid"
            @click="topFunction">
          <i class="fa-solid fa-arrow-up"></i>
          <span class="d-inline-block">Top</span>
        </button>
      </a>
    </section>
  </div>
</template>

<script>
import {  reactive, toRefs } from "vue";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";

const tabs = [
  {
    label: "SAP",
    value: 1,
    description: `<p>{{$t('message.StronglySAP')}}<br>
                  {{$t('message.OurSAPConsultants')}}<br>
                  {{$t('message.EnableUsto')}}<br>
                  {{$t('message.DigitalTransformation')}}<br>
                  {{$t('message.ConsequentlyExpanded')}}<br>
                  {{$t('message.ToEmbrace')}}</p>
                <ul>
                  <li><p> SAP S/4Hana</p></li>
                  <li><p>{{$t('message.FuncModules')}}</p></li>
                  <li><p>{{$t('message.TechModules')}}</p></li>
                  <li><p>{{$t('message.ProjectManagement')}}</p></li>
                  <li><p>{{$t('message.ManagementTraining')}}</p></li>
                </ul>`,
    color: "black",
  },
  {
    label: "Business Applications",
    value: 2,
    description: `  <p>{{$t('message.OnAScope')}}<br/>
                  {{$t('message.OurConsultantsWill')}} <br/>
                  {{$t('message.WeAreHereToHelp')}} <br/>
                  {{$t('message.TalentInSalesforce')}}
                </p>
                <ul>
                  <li><p> SAP</p></li>
                  <li><p>{{$t('message.Salesforce')}}</p></li>
                  <li><p>{{$t('message.MYSDynamics')}}</p></li>
                </ul>`,
    color: "black",
  },
  {
    label: "Cloud",
    value: 3,
    description: ` <p>{{$t('message.EstimatedCloud')}} <br/>
                  {{$t('message.CloudWithHelp')}}
                </p>
                <ul>
                  <li><p> {{$t('message.DevOpsEng')}}</p></li>
                  <li><p>{{$t('message.DigitalPlatforms')}}</p></li>
                  <li><p> {{$t('message.CloudArch')}}</p></li>
                  <li><p> {{$t('message.DataEng')}}</p></li>

                </ul>`,
    color: "black",
  },
];


import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";

export default {
  name: 'OurServices',
  props: [],
  components: {
    HeaderComponent,
    SideBar,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
  },
  setup() {
    const state = reactive({
      selectedTab: tabs[0].value,
    });
    function getActive(tab) {
      return state.selectedTab === tab;
    }
    return {
      tabs,
      getActive,
      ...toRefs(state),
    };
  },
  data() {
    return {
      isActive: false,
      showHeader: false,
    }
  },
  methods: {
    shadowMenu: function () {
      // alert("ok")
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;
    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },
  },
}
</script>

<style lang="scss" scoped>
#top-btn {
  color: #000;
}
#\31 {
  padding-top: 30px;
}
#\33 {
  padding-top: 30px;
}
</style>
