<template>
  <div>
    <HeaderComponent @sh0w="shadowMenu()"/>
    <div id="job-page">
      <div class="container" >
        <div class="filter-div">
          <div class="row">
            <div class="col-md-6 col-6" style="padding-left:0px">
              <a
                  href="/#JobSearch"
                  class="search-btn d-flex align-items-center justify-content-center">
                {{$t('message.BackSearch')}}
            </a>
            </div>
            <div class="col-md-6 col-6" style="padding-right:0px">
              <a @click="showModal = true"><button class="reset-btn">{{$t('message.ApplyNow')}}</button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="vacancy-wrapper">
          <div class="icon-wrapper">
            <i class="fa-solid fa-database"></i>
          </div>
          <div class="content-wrapper">
            <div class="job-title">
              <h3>{{ jobtitle }}
              </h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-5">
                <div class="location">
                  <h3>Location</h3>
                  <p>{{ joblocation }}</p>
                </div>
              </div>
              <div class="col-md-3 col-4">
                <div class="skill">
                  <h3>Required Skills</h3>
                  <p>{{ jobskills }}</p>
                </div>
              </div>
              <div class="col-md-5 col-3">
                <div class="type">
                  <h3>Type</h3>
                  <p>{{ jobtype }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="description">
                  <h3>Description</h3>
                  <p>{{ jobdescription }}</p>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="share">
                  <h3>Share</h3>
                  <ul>
                    <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a target="_blank" :href="'https://www.facebook.com/sharer.php?u='+currentlink"><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li><a target="_blank" :href="'https://www.linkedin.com/sharing/share-offsite/?url='+currentlink"><i class="fa-brands fa-linkedin-in"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>
  </div>
  <Teleport to="body">
  <!-- use the modal component, pass in the prop -->
  <modal :show="showModal" @close="showModal = false">
    <template #header>
      <h3>Email us at</h3>
    </template>
  </modal>
</Teleport>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";
import axios from 'axios'
import Modal from '@/components/ApplyJob.vue'

export default {
  name: 'JobPage',
  props: [],
  components: {
    Modal,
    HeaderComponent,
    SideBar,
  },
  data() {
    return {
      isActive: false,
      jobtitle: '',
      joblocation: '',
      jobskills: '',
      jobtype: '',
      jobdescription: '',
      jobapply: '',
      showModal: false,

      currentlink: '',
    }
  },
  methods: {

    shadowMenu: function () {
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;

    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },

    getJob() {
      axios.get(this.$api+'/getjob.php?jobid='+this.$route.params.slug)
      .then((response) => {
          var result = response.data;
          console.log(result)

          this.jobtitle = result[0].title
          this.joblocation = result[0].location
          this.jobskills = result[0].skills
          this.jobtype = result[0].type
          this.jobdescription = result[0].description
          this.jobapply = result[0].apply
      });
    },
  },
  mounted() {
    this.getJob()
    this.currentlink = window.location.href
  }
}
</script>

<style lang="scss" scoped>

</style>
