<template>
  <header id="header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-9  align-self-center">
          <router-link to="/" class="blue-logo">
            <img src="../assets/images/logo.png" alt="logo">
          </router-link>
          <router-link to="/" class="white-logo">
            <img src="../assets/images/logowhite.png" alt="logo">
          </router-link>
        </div>
        <div class="col-md-9 col-3  text-end align-self-center">
          <div class="menu-wrapper text-end d-flex align-items-center">
            <div class="desktop-menu">
              <ul>
                <li><a href="/#JobSearch">Job search</a></li>
                <li><a href="https://api.whatsapp.com/send?phone=393319268512">Whatsapp us</a></li>

                <li @click="showLangs" class="dropdown-lang"><a href="#">Languages</a>
                  <div class="langmenu" v-if="showLang">
                    <div @click="selectlang('it')">Italiano</div>
                    <div @click="selectlang('en')">English</div>
                  </div>
                </li>

            </ul>
            </div>
            <span class="toggle-icon d-inline-block " @click="showMenu()" role="button">
          </span>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: ['courseMenu', 'mode'],
  components: {
    
  },
  data() {
    return {
      lang: 'en',
      showLang: false,
    }
  },
  methods: {
    showMenu() {
      // alert("ok")
      this.$emit("sh0w")
    },
    selectlang(lang) {
      this.lang = lang;
      localStorage.setItem("lang", lang.toLowerCase());
      this.$i18n.locale = localStorage.getItem("lang");
    },
    defaultLang() {
      if (localStorage.getItem("lang") === null) {
        this.lang = 'en'
        this.$i18n.locale = 'en';
      } else {
        this.lang = localStorage.getItem("lang")
        this.$i18n.locale = localStorage.getItem("lang");
      }
    },
    showLangs() {
      if(this.showLang)
        this.showLang = false
      else
        this.showLang = true
    },
    handleSCroll() {
      // let header = document.querySelector("header");
      let header = document.getElementById("header")
      if (window.scrollY >= 100 && !header.className.includes('sticky')) {
        header.classList.add('sticky');
      } else if (window.scrollY <= 100) {
        header.classList.remove('sticky');
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleSCroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleSCroll);
  },
  mounted() {
    this.defaultLang()
  }

}
</script>

<style>
</style>