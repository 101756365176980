<template>

  <div>
    <HeaderComponent @sh0w="shadowMenu()"/>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>
    <div id="submit-cv-form">
      <div class="container-fluid">
        <div class="row">
            <div class="form">

              <div id="submit-cv-form">
                <div class="container-fluid" style="padding-left: 0 !important;">
                  <div class="row">
                    <div class="col-xl-5 align-self-center text-center text-lg-start">
                      <h2>{{ $t('message.SubmitCV') }}</h2>
                    </div>
                    <div class="col-xl-7 align-self-center">
                      <div class="form">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="first-name">{{ $t('message.FirstName') }}</label>
                              <input required type="text" id="first-name" class="form-control">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="last-name">{{ $t('message.LastName') }}</label>
                              <input type="text" id="last-name" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="email">{{ $t('message.EmailAddress') }}</label>
                              <input type="email" id="email" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="phone-number">{{ $t('message.PhoneNumber') }}</label>
                              <input type="text" id="phone-number" class="form-control">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="City">{{ $t('message.City') }}</label>
                              <input type="text" id="City" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="country">{{ $t('message.Country') }}</label>
                              <input type="text" id="country" class="form-control">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="job-role">{{ $t('message.JobRole') }}</label>
                              <input type="text" id="job-role" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="linkedin-profile">{{ $t('message.LinkedinProfile') }}</label>
                              <input type="text" id="linkedin-profile" class="form-control">
                            </div>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group text-center">
                              <input type="file" id="upload-cv"/>
                              <label for="upload-cv" class="btn-style icon-btn mb-1">
                                <i class="fa-solid fa-file-arrow-up"></i>

                                <span>{{ $t('message.UploadCV') }}</span>
                              </label>
                              <p>*.pdf .png .jpg</p>
                            </div>
                            <div class="form-group text-center consent">
                              <input type="checkbox" id="agree">
                              <label for="agree">
                                <span><i class="fa fa-check"></i></span>
                                <p>{{ $t('message.Consent') }}</p>
                              </label>

                            </div>
                          </div>
                        </div>


                        <div class="row">

                          <div class="row">
                            <div class="col-sm-12">
                                <div class="action-div">
                                  <button type="submit" class="btn-style">
                                    <i class="fa-solid fa-hand-pointer"></i>{{ $t('message.Submit') }}
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <a href="#firstPage">
      <button id="top-btn" class="d-none d-lg-grid"
          @click="topFunction">
        <i class="fa-solid fa-arrow-up"></i>
        <span class="d-inline-block">Top</span>
      </button>
    </a>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";

export default {
  name: 'SubmitPage',
  props: [],
  components: {
    HeaderComponent,
    SideBar
  },
  data() {
    return {
      isActive: false,
      showHeader: false,
    }
  },
  methods: {
    shadowMenu: function () {
      // alert("ok")
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;
    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },
  },
}
</script>

<style lang="scss" scoped>
#top-btn {
  color: #000;
}
</style>
