<template>
  <div class="home">
    <full-page :options="options" @afterLoad="afterLoad" @onLeave="onLeave">
      <div class="section" data-anchor="firstPage">
        <section class="sec1 banner thumb-bar">
          <div class="video-wrapper">
            <video autoplay muted loop id="myVideo" class="d-none d-md-block" data-keepplaying>
              <source src="../assets/video/homevid.mp4" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>
            <video autoplay muted loop id="mobileVideo" class="d-block d-md-none" data-keepplaying>
              <!-- <source src="../assets/video/hint-mobile-video.mp4" type="video/mp4"> -->
              <source src="../assets/video/mobilevid.mp4" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>
            <div class="container-fluid">
              <div class="content-wrapper">
                <h1><b style="font-weight: 500;">{{$t('message.LetsFindOut')}}</b> <br />
                  {{$t('message.Solutions')}} <br />
                  <b style="font-weight: 500;">{{$t('message.Together')}}</b></h1>
                <!-- <button class="prev" @click="$refs.fullpage.api.moveSectionUp()">
                  Prev
                </button>-->
              </div>

              <a href="#fourthPage">
                <button id="top-btn" class="d-none d-lg-grid p-3" style="/*background: rgba(0,0,0,0.5)*/ background:transparent;">
                  <span class="d-inline-block">Scroll</span>
                  <i class="fa-solid fa-arrow-down"></i>
                </button>
              </a>
            </div>

          </div>
        </section>
      </div>
      <div class="section about-section  thumb-bar" data-anchor="secondPage">
        <AboutUs/>
      </div>
      <div class="section service-section thumb-bar" data-anchor="thirdPage">
        <OurServices/>
      </div>
      <div class="section service-section thumb-bar" data-anchor="JobSearch" style="justify-content: normal;">
        <JobSearch/>
      </div>
      <div class="section service-section thumb-bar" data-anchor="SubmitCV" style="justify-content: normal;">
        <SubmitCV/>
      </div>
      <div class="section section-contact transparent-header-section thumb-bar" data-anchor="fourthPage">
        <ContactUs/>
      </div>
    </full-page>
    <HeaderComponent @sh0w="shadowMenu()" style="background:transparent;"></HeaderComponent>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>
  </div>
</template>


<script>
import AboutUs from "@/views/AboutView";
import OurServices from "@/views/OurServices";
import ContactUs from "@/views/ContactUs";
import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";

import JobSearch from "@/views/JobSearch";
import SubmitCV from "@/views/SubmitCV";

export default {
  name: 'HomeView',
  components: {
    AboutUs,
    OurServices,
    ContactUs,
    HeaderComponent,
    SideBar,

    JobSearch,
    SubmitCV,
  },
  data() {
    return {
      isActive: false,
      showHeader: false,
      options: {
        licenseKey: 'YOUR_LICENSE_KEY', // https://alvarotrigo.com/fullPage/extensions/requestKey.html
        navigation: false,
        anchors: ['firstPage', 'secondPage', 'thirdPage', 'fourthPage'],
        menu: '#menu',
        scrollOverflow: false,
        scrollBar: false
        // other options
      },
    }
  },
  methods: {
    afterLoad(origin, destination,) {
      console.log('After load: ' + destination.anchor);
    },
    onLeave(origin) {
      console.log('Leaving section: ' + origin.anchor);
    },
    shadowMenu: function () {
      // alert("ok")
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;
    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },
  },

  mounted() {

  },

  unmounted() {

  }
}
</script>

<style lang="scss" scoped>

</style>
