const en = {
  message: {
      // MENU
      mAboutus: 'About us',
      mOurServices: 'Our services',
      mSubmitCV: 'Submit CV',
      mJobSearch: 'Job Search',
      mContactUs: 'Contact us',

      // HOME
      LetsFindOut: 'Let\'s find out',
      Solutions: 'IT solutions',
      Together: 'Together',

      // ABOUT
      WeAre: 'We are',
      QualifiedTeam: 'a qualified team of',
      SAPconsultants: 'SAP consultants',
      WorkingToFind: 'constantly working to find out and provide better IT',
      SolutionsForClients: 'solutions for our clients. Our aim is to',
      SupportDuring: 'support you during',
      allPhasesOf: 'all phases of',
      yourProjects: 'your projects',
      thanks: 'thanks',
      toOur: 'to our',
      nationalITNet: 'national and international SAP & IT network.',
      WeCanFulfill: 'We can fulfill your need-based:',
      TimeMaterial: 'Time and Material',
      Outsourcing: 'Outsourcing',
      Milestone: 'Milestone',

      // Contact
      WouldLikeStaff: 'Would you like to staff',
      aProject: ' a project?',
      StayTuned: 'Stay tuned',
      WeAreIn: 'We are in',
      Italy: 'Italy',

      // Job Page
      BackSearch: 'Back to search',
      ApplyNow: 'Apply now',

      // Job Search
      Search: 'Search',
      ResetFilters: 'Reset filters',

      //Submit CV
      SubmitCV: 'Submit your CV',
      FirstName: 'First name',
      LastName: 'Last name',
      EmailAddress: 'Email address',
      PhoneNumber: 'Phone number',
      City: 'City',
      Country: 'Country',
      JobRole: 'Job role',
      LinkedinProfile: 'Linkedin profile',
      UploadCV: 'Upload your CV',
      Consent: 'I consent to storing and processing my personal data',
      Submit: 'Submit',

      // Our Services
      Services: 'Services',
      //SAP
      StronglySAP: 'Strongly dedicate to SAP',
      OurSAPConsultants: 'Our SAP internal consultants and networks',
      EnableUsto: 'enable us to support our client\'s entire',
      DigitalTransformation: 'digital transformation through the rapid evolution of SAP.',
      ConsequentlyExpanded: 'Consequently, we have expanded our smart technology portfolio',
      ToEmbrace: 'to embrace a more rounded and comprehensive spectrum:',
      FuncModules: 'Functional Modules',
      TechModules: 'Technical Modules',
      ProjectManagement: 'Project Management',
      ManagementTraining: 'Change Management and Training',
      //BUSINESS
      OnAScope:'On a scope of works basis or on a time and material basis,',
      OurConsultantsWill: 'our consultants will provide you with the solution you need.',
      WeAreHereToHelp: 'We are here to help you, no matter if you are trying to find',
      TalentInSalesforce: 'talent in the Salesforce market or if you need assistance with Oracle profiles.',
      Salesforce: 'Salesforce',
      MYSDynamics: 'MYS Dynamics',
      //CLOUD
      EstimatedCloud: 'It is estimated that cloud and data practices are growing rapidly.',
      CloudWithHelp: 'With our help, you\'ll be able to stay one step ahead of your digital transformation:',
      DevOpsEng: 'DevOps Engineering',
      DigitalPlatforms: 'Digital Platforms',
      CloudArch: 'Cloud Architecture',
      DataEng: 'Data Engineering',
  }
}

export default en