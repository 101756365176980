<template>

  <div>
    <HeaderComponent @sh0w="shadowMenu()"/>
    <SideBar @hide="hideMenu()" :class="{'active' : isActive}"></SideBar>
    <section id="about-us">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8 mt-5 textcontent">
            <h2>
              <span>{{$t('message.WeAre')}}</span> {{$t('message.QualifiedTeam')}} <span>{{$t('message.SAPconsultants')}}</span><br>
              {{$t('message.WorkingToFind')}}<br>
              {{$t('message.SolutionsForClients')}} <br>
               <span> {{$t('message.SupportDuring')}} </span> {{$t('message.allPhasesOf')}} <span>{{$t('message.yourProjects')}}</span> {{$t('message.thanks')}}<br>
              {{$t('message.toOur')}} <span>{{$t('message.nationalITNet')}}</span><br/>
              {{$t('message.WeCanFulfill')}}
            </h2>
            <ul>
              <li><span class="dot" style="vertical-align:super;">⚫</span><strong> {{$t('message.TimeMaterial')}}</strong></li>
              <li><span class="dot" style="vertical-align:super;">⚫</span><strong> {{$t('message.Outsourcing')}}</strong></li>
              <li><span class="dot" style="vertical-align:super;">⚫</span><strong> {{$t('message.Milestone')}}</strong></li>
            </ul>
          </div>

          <div class="col-sm-4 bgimg">
            <img class="sideimg" src="@/assets/images/aboutimg.jpg"/>
          </div>
        </div>
      </div>

      <a href="#firstPage">
        <button id="top-btn" class="d-none d-lg-grid"
            @click="topFunction">
          <i class="fa-solid fa-arrow-up"></i>
          <span class="d-inline-block">Top</span>
        </button>
      </a>
    </section>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import SideBar from "@/components/SideBar";
export default {
  name: 'AboutUs',
  props: [],
  components: {
    HeaderComponent,
    SideBar
  },
  data() {
    return {
      isActive: false,
      showHeader: false,
    }
  },
  methods: {
    shadowMenu: function () {
      // alert("ok")
      // this.$emit("sh0w2")
      let siteBody = document.body;
      siteBody.classList.add("active");
      this.isActive = true;
    },
    hideMenu: function () {
      let siteBody = document.body;
      siteBody.classList.remove("active");
      this.isActive = false;
    },
  },
}
</script>

<style lang="scss" scoped>
#top-btn {
  color: #000;
}
</style>
