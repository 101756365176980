<template>
  <section id="contact-us">
    <!--<video autoplay muted loop id="myVideo">
      <source src="../assets/video/footervideo.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>-->
    <!--
    <div class="container-fluid position-relative">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="left-content">
            <h2>{{$t('message.WouldLikeStaff')}}<br/>
             {{$t('message.aProject')}}</h2>
            <div class="social-links">
              <h2>{{$t('message.StayTuned')}}</h2>
              <ul>
                <li>
                  <router-link to="#"><i class="fa-brands fa-instagram"></i></router-link>
                </li>
                <li>
                  <router-link to="#"><i class="fa-brands fa-facebook-f"></i></router-link>
                </li>
                <li>
                  <router-link to="#"><i class="fa-brands fa-linkedin-in"></i></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-5 ">
          <div class="right-contact">
            <p>{{$t('message.WeAreIn')}}</p>
            <h3>{{$t('message.Italy')}}</h3>
            <strong>Perugia</strong>
            <ul>
              <li><p>Via Settevalli, 556<br/>
                06129 PG, Italy</p></li>
              <li>
                <a id="tel" href="tel:+3319268512"><span>Tel </span>+3319268512</a>
              </li>
              <li>
                <a href="mailto:info@hintsolution.it">info@hintsolution.it</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    -->

    <div class="container-fluid " style="padding-top:0;">
      <div class="row wouldyou">
        <h2>Would you like to staff<br/>a project?</h2>
        <a href="mailto:info@hintsolutions.it" class="dropus">DROP US A LINE <i class="fa-solid fa-arrow-right"></i></a>
      </div>

      <div class="row wearein">
        <div class="col-sm-5">

        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
          <p>We are in</p>
          <p class="italy">Italy<br/>Perugia</p>
        </div>
      </div>
    </div>

    <div class="bottom desktop">
      <div class="container" style="margin-left: 0;padding-left:0;">
        <div class="row mt-5 pt-5 align-items-center">
          <div class="col-sm-3 stick">
            <h5>Stay tuned</h5>
            <ul>
              <li>
                <router-link to="#"><i class="fa-brands fa-instagram"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa-brands fa-facebook-f"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa-brands fa-linkedin-in"></i></router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <form class="form-inline">
              <div class="input-group mb-3">
                <input id="newsletter" name="newsletter" placeholder="Subscribe to our newsletter" type="email" class="form-control">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="submit">subscribe</button>
                </div>
              </div>

              <input type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                I have read and agree to the <u>Privacy policy</u> of Hint S.r.l
              </label>
              
            </form>
          </div>
          <div class="col-sm-3 info">
            <p>
              HINT S.r.l<br/>
              via Settevalli, 556<br/>
              06129 PG, Italy<br/>
              P.IVA e CF IT03881840544
            </p>
            <a href="/privacy.pdf">Privacy</a>
            <a href="/privacy.pdf">Cookie policy</a>
            <span class="badge badge-secondary" ><a href="https://api.whatsapp.com/send?phone=393319268512">TEL +39 3319268512</a><br/><a class="mail" href="mailto:info@hintsolutions.it">info@hintsolutions.it</a></span>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom mobile">
      <div class="container">
        <div class="row mt-5 pt-5 align-items-center">
          <div class="col-sm-3 infomobile">
            <p>
              HINT S.r.l<br/>
              via Settevalli, 556<br/>
              06129 PG, Italy<br/>
              P.IVA e CF IT03881840544
            </p>
            <a href="/privacy.pdf">Privacy</a>
            <a href="/privacy.pdf">Cookie policy</a>
            <span class="badge badge-secondary" style="top: 45px;right: 20px;" ><a href="https://api.whatsapp.com/send?phone=393319268512">TEL +39 3319268512</a><br/><a href="mailto:info@hintsolutions.it">info@hintsolutions.it</a></span>
          </div>
          
          <div class="col-sm-6 subscribe">
            <form class="form-inline">
              <div class="input-group mb-3">
                <input id="newsletter" name="newsletter" placeholder="Subscribe to our newsletter" type="email" class="form-control">
                <div class="input-group-append" style="border-bottom: 1px solid #fff;">
                  <button class="btn btn-outline-secondary" type="submit">subscribe</button>
                </div>
              </div>

              <input type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                I have read and agree to the <u>Privacy policy</u> of Hint S.r.l
              </label>
              
            </form>

            <a href="#firstPage">
                <button id="top-btn" class="mobile d-lg-grid"
                    @click="topFunction">
                  <img src="@/assets/images/arrow.png" style="width: 40px;margin-right: 20px;" />
                </button>
              </a>
          </div>

          <div class="col-sm-3 stick" style="margin-top: 17px;">
            <h5>Stay tuned</h5>
            <ul>
              <li>
                <router-link to="#"><i class="fa-brands fa-instagram"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa-brands fa-facebook-f"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa-brands fa-linkedin-in"></i></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <a href="#firstPage">
      <button id="top-btn" class="d-none d-lg-grid"
          @click="topFunction">
        <i class="fa-solid fa-arrow-up"></i>
        <span class="d-inline-block">Top</span>
      </button>
    </a>
  </section>
</template>

<script>


export default {
  name: 'ContactUs',
  props: [],
  components: {},
  data() {
    return {}
  },
  methods: {
    topFunction: function () {
      window.scrollTo(0, 0);
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
