import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import AboutUs from "@/views/AboutView";
import OurServices from "@/views/OurServices";
import SubmitCv from "@/views/SubmitCV";
import JObSearch from "@/views/JobSearch";
import ContactUs from "@/views/ContactUs";
import JobPage from "@/views/JobPage";

const routes = [
    {
        path: "/",
        name: "Hintsolutions",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: AboutUs,
    },
    {
        path: "/services",
        name: "OurServices",
        component: OurServices,
    },
    {
        path: "/submit",
        name: "SubmitCv",
        component: SubmitCv,
    },
    {
        path: "/search",
        name: "JObSearch",
        component: JObSearch,
    },
    {
        path: "/contact",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/job-page/:slug",
        name: "JobPage",
        component: JobPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router;
