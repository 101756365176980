const it = {
    message: {
        // MENU
        mAboutus: 'Chi siamo',
        mOurServices: 'Servizi',
        mSubmitCV: 'Invia CV',
        mJobSearch: 'Cerca lavoro',
        mContactUs: 'Contattaci',
        
        // HOME
        LetsFindOut: 'Scopriamo nuove',
        Solutions: 'Soluzioni IT',
        Together: 'Insieme',
  
        // ABOUT
        WeAre: 'Siamo',
        QualifiedTeam: 'un team qualificato di',
        SAPconsultants: 'consulenti SAP',
        WorkingToFind: 'lavorando costantemente per scoprire e fornire migliori',
        SolutionsForClients: 'soluzioni IT ai nostri clienti. Il nostro obiettivo è quello di',
        SupportDuring: 'supportarvi durante',
        allPhasesOf: 'tutte le fasi',
        yourProjects: 'dei vostri progetti',
        thanks: 'grazie',
        toOur: 'alla nostra',
        nationalITNet: 'rete SAP & IT nazionale e internazionale.',
        WeCanFulfill: 'Possiamo soddisfare le tue esigenze in base a:',
        TimeMaterial: 'Time and Material',
        Outsourcing: 'Outsourcing',
        Milestone: 'Milestone',
  
        // Contact
        WouldLikeStaff: 'Vorresti assumere personale per',
        aProject: ' un progetto?',
        StayTuned: 'Seguici',
        WeAreIn: 'Ci trovi qui',
        Italy: 'Italia',
  
        // Job Page
        BackSearch: 'Torna alla ricerca',
        ApplyNow: 'Candidati',
  
        // Job Search
        Search: 'Cerca',
        ResetFilters: 'Rimuovi filtri',
  
        //Submit CV
        SubmitCV: 'Invia il tuo CV',
        FirstName: 'Nome',
        LastName: 'Cognome',
        EmailAddress: 'Indirizzo email',
        PhoneNumber: 'Numero di telefono',
        City: 'Città',
        Country: 'Paese',
        JobRole: 'Ruolo',
        LinkedinProfile: 'Profilo linkedin',
        UploadCV: 'Carica il tuo CV',
        Consent: 'Acconsento alla memorizzazione e al trattamento dei miei dati personali',
        Submit: 'Invia',

        // Our Services
        Services: 'Servizi',
        //SAP
        StronglySAP: 'Dedicato fortemente a SAP',
        OurSAPConsultants: 'I nostri consulenti SAP interni',
        EnableUsto: 'ci permettono di aiutare l\'intera',
        DigitalTransformation: 'trasformazione digitale del nostro cliente attraverso la rapida evoluzione di SAP.',
        ConsequentlyExpanded: 'Di conseguenza, abbiamo ampliato il nostro portafoglio di tecnologie',
        ToEmbrace: 'per abbracciare uno spettro più completo e completo:',
        FuncModules: 'Moduli funzionali',
        TechModules: 'Moduli tecnici',
        ProjectManagement: 'Gestione del progetto',
        ManagementTraining: 'Gestione del cambiamento e formazione',
        //BUSINESS
        OnAScope:'In base all\'ambito dei lavori o in base al tempo e ai materiali,',
        OurConsultantsWill: 'i nostri consulenti ti forniranno la soluzione di cui hai bisogno.',
        WeAreHereToHelp: 'Siamo qui per aiutarti, non importa se stai cercando di trovare',
        TalentInSalesforce: ' talento nel mercato Salesforce o se hai bisogno di assistenza con i profili Oracle.',
        Salesforce: 'Salesforce',
        MYSDynamics: 'MYS Dynamics',
        //CLOUD
        EstimatedCloud: 'Si stima che le pratiche relative a cloud e dati stiano crescendo rapidamente.',
        CloudWithHelp: 'Con il nostro aiuto, sarai in grado di stare un passo avanti nella tua trasformazione digitale:',
        DevOpsEng: 'Ingegneria DevOps',
        DigitalPlatforms: 'Piattaforme digitali',
        CloudArch: 'Architettura Cloud',
        DataEng: 'Ingegneria dei dati',
    }
}
  
export default it