import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

import 'vue-fullpage.js/dist/style.css'
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
import Dropdown from 'vue-dropdowns'
import VueUniversalModal from 'vue-universal-modal';

import { createI18n } from 'vue-i18n'
import en from './lang/en.js'
import it from './lang/it.js'

const i18n = createI18n({
    locale: 'en',
    messages: {
        en,
        it
    }
});

const app = createApp(App)

app.use(router)
.use(VueFullPage)
.use(i18n)
.use(Dropdown)
.use(VueUniversalModal, {
    teleportTarget: '#modals',
  })
.mount('#app')


app.config.globalProperties.$api = 'https://hint.behindthis.app/php'